import React, {useState} from 'react';
import {connect} from 'react-redux';
import {Button, Form, Input, Breadcrumb, Row, Col, message} from 'antd';
import {resetPassword} from '~actions/user';
import Loading from '~components/page-loader';
import {Link, navigate} from 'gatsby';
import ChevronRight from '~components/icons/chevron-right';

import './styles.scss';

const ResetPassword = ({location, reset}) => {
  const [loading, setLoading] = useState(false);

  const handleResetPassword = async values => {
    try {
      setLoading(true);
      const {search} = location;
      if (!search) return;

      const searchParams = new URLSearchParams(search.slice(1));
      const res = await reset({
        ...values,
        token: searchParams.get('token').replaceAll(' ', '+'),
      });
      if (!res) {
        return;
      }

      message.success('Đặt lại mật khẩu thành công!');
      navigate('/login');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='reset-password'>
      <Loading loading={loading} />
      <Row justify='center' className='breadcrumb-header com-bg-gray'>
        <Col xs={24} md={23} lg={21}>
          <Breadcrumb separator={<ChevronRight fill='#D8D6D6' />}>
            <Breadcrumb.Item>
              <Link to='/home' className='pro-title-name'>
                Trang chủ
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <div className='pro-title-name'>Đặt lại mật khẩu</div>
            </Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row justify='center' className='content'>
        <Col xs={24} md={23} lg={21}>
          <div className='reg-title'>Đặt lại mật khẩu</div>
          <Form
            name='reset-form'
            className='reset-form'
            onFinish={handleResetPassword}
            labelCol={{span: 8}}
            wrapperCol={{span: 16}}
          >
            <Form.Item
              label='Mật khẩu'
              name='newPassword'
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập mật khẩu',
                },
                {
                  min: 7,
                  message: 'Mật khẩu phải có ít nhất 7 kí tự',
                },
                {
                  max: 32,
                  message: 'Mật khẩu không được quá 32 kí tự',
                },
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
            <Form.Item
              label='Nhập lại mật khẩu'
              name='repeatedPassword'
              dependencies={['newPassword']}
              rules={[
                {
                  required: true,
                  message: 'Vui lòng nhập mật khẩu!',
                },
                {
                  min: 7,
                  message: 'Mật khẩu phải có ít nhất 7 kí tự!',
                },
                {
                  max: 32,
                  message: 'Mật khẩu không được quá 32 kí tự!',
                },
                ({getFieldValue}) => ({
                  async validator(_, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return;
                    }

                    throw new Error('Mật khẩu không trùng khớp, vui lòng nhập lại!');
                  },
                }),
              ]}
              hasFeedback
            >
              <Input.Password />
            </Form.Item>
            <Form.Item className='submit-form'>
              <Button className='btn-submit' type='primary' htmlType='submit'>
                Lưu mật khẩu
              </Button>
            </Form.Item>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

export default connect(
  state => ({
    customer: state.user.customer,
  }),
  dispatch => ({
    reset: request => dispatch(resetPassword(request)),
  })
)(ResetPassword);
