import React from 'react';
import Layout from '~components/layout';
import Seo from '~components/seo';
import ResetPassword from '~containers/authenticate/reset-password';

const ResetPasswordPage = props => {
  return (
    <Layout>
      <Seo title='Reset Password' />
      <ResetPassword {...props} />
    </Layout>
  );
};

export default ResetPasswordPage;
